import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from "./Components/Navbar/Navbar";
import { Home } from './Pages/Home/Home';
import AOS from 'aos';
import React, { useState, useEffect } from 'react';
import { UniLoder } from './Components/Loder/UniLoder';
import 'aos/dist/aos.css';
import { ServiceDetails } from './Components/Services/ServiceDetails';
import { ServiceDisplay } from './Components/Services/ServiceDisplay';
import Footer from './Components/Footer/Footer';
import Chat from './Components/Chat/Chat';
import Register from './Components/Users/Register';
import Login from './Components/Users/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserChat from './ChatRoom/New/ChatApp';
import CuChat from './ChatRoom/CostomerChat/CuChat';
import MessageList from './ChatRoom/CostomerChat/MessageList';
import { Aboutus } from './Pages/About/About';
import { ContactUs } from './Pages/ContactUs/ContactUs';
import Web from './ChatRoom/Web/Web';
import Marketing from './ChatRoom/Marketing/Marketing';
import Printing from './ChatRoom/Printing/Printing';
import Legal from './ChatRoom/Legal/Legal';
import Accounting from './ChatRoom/Acounting/Accounting';
import Advertise from './ChatRoom/Advertise/Advertise';
import WebCity from './ChatRoom/Web/WebCity';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import Steps from './Components/HomeComponents/Step';
import { Portfolio } from './Components/Portfolio/Portfolio';
import { DisplayPortfolio } from './Components/Portfolio/DisplayPortfolio';
import Tandc from './Pages/Tandc/Tandc';
import UserInfoModal from './UserInfoModal/UserInfoModal';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import CardServices from './Components/Services/CardServices';
import NotFound from './Pages/NotFound/NotFound';

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    AOS.init({ duration: 2000, easing: 'ease' });
    AOS.refresh();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const userRef = doc(db, 'users', userId);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          // Open modal if email or mobile is missing
          if (!userData.email || !userData.mobile) {
            setIsModalOpen(true);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const hideFooter = ['/chatapp', '/cuchat', '/web', '/printing', '/advertise', '/marketing', '/legal', '/accounting'].some(path => location.pathname.startsWith(path));

  return (
    <div className="App">
      {loading ? (
        <UniLoder />
      ) : (
        <>
          <Navbar />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/service/:ServiceId" element={<ServiceDetails />} />
            <Route path="/service" element={<ServiceDisplay />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/chatapp" element={<UserChat />} />
            <Route path="/cuchat/:userId" element={<CuChat />} />
            <Route path="/mlist" element={<ProtectedRoute><MessageList /></ProtectedRoute>} />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/printing" element={<Printing />} />
            <Route path="/legal" element={<Legal />} />
        

            <Route path="/accounting" element={<Accounting />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/step" element={<Steps />} />
            <Route path="/upload-portfolio" element={<Portfolio />} />
            <Route path="/portfolio" element={<DisplayPortfolio />} />
            <Route path="/privacy-policy" element={<Tandc />} />
            <Route path="/webcity" element={<ProtectedRoute><WebCity /></ProtectedRoute>} />
            <Route path="/web/:cityId" element={<Web />} />
            <Route path="/hire" element={<CardServices />} />
            <Route path="*" element={<NotFound />} />


          </Routes>

          <UserInfoModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />

          {!hideFooter && <Footer />}
        </>
      )}
    </div>
  );
}

export default App;
