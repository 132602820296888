import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { textdb } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Helmet } from 'react-helmet';
import "./Dportfolio.css";

export const DisplayPortfolio = () => {
  const [services, setServices] = useState([]); // Dynamically fetched services
  const [selectedService, setSelectedService] = useState(''); // Default to no selection initially
  const [portfolioQuery, setPortfolioQuery] = useState(null);
  const [portfolio, loading, error] = useCollection(portfolioQuery);

  // Fetch services from Firestore
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const servicesCollection = collection(textdb, 'Services');
        const serviceSnapshot = await getDocs(servicesCollection);
        const serviceList = serviceSnapshot.docs.map(doc => doc.data().title); // Assuming 'title' field exists
        setServices(serviceList);

        // Set the first service as selected by default if available
        if (serviceList.length > 0) {
          setSelectedService(serviceList[0]);
          setPortfolioQuery(query(collection(textdb, 'portfolio'), where('service', '==', serviceList[0])));
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  // Update portfolio query when selected service changes
  useEffect(() => {
    if (selectedService) {
      setPortfolioQuery(query(collection(textdb, 'portfolio'), where('service', '==', selectedService)));
    }
  }, [selectedService]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <div className='main-display-portfolio'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio</title>
        <meta
          name="description"
          content="Welcome to our vibrant portfolio, a space where our journey unfolds through creativity, innovation, and dedication. Here, we showcase the culmination of our passion and expertise, offering a glimpse into our world of dynamic projects and remarkable accomplishments. Each entry reflects our commitment to excellence, our drive for innovation, and our unwavering pursuit of turning ideas into impactful realities. Explore our portfolio and witness the stories of our endeavors, crafted with enthusiasm and a relentless pursuit of greatness."
        />
      </Helmet>

      <div data-aos="fade-up" className='wearehiring'>
        <h2>Welcome To Our <span className='hiring'>Portfolio</span></h2>
        <p>Welcome to our vibrant portfolio, a space where our journey unfolds through creativity, innovation, and dedication. Here, we showcase the culmination of our passion and expertise, offering a glimpse into our world of dynamic projects and remarkable accomplishments. Each entry reflects our commitment to excellence, our drive for innovation, and our unwavering pursuit of turning ideas into impactful realities. Explore our portfolio and witness the stories of our endeavors, crafted with enthusiasm and a relentless pursuit of greatness.</p>
      </div>

      {/* Render the dynamic service tabs */}
      <div className='tabs'>
        {services.length > 0 ? (
          services.map((service) => (
            <button
              key={service}
              className={selectedService === service ? 'active' : ''}
              onClick={() => setSelectedService(service)}
            >
              {service}
            </button>
          ))
        ) : (
          <p>Loading services...</p>
        )}
      </div>

      <div className='display-portfolio-background'>
        <h2>Here is Our Some Previous Work</h2>
        {loading && <p>Loading...</p>}
        {error && <p>Error fetching portfolio</p>}
        {portfolio && (
          <div className='display-portfolio'>
            {portfolio.docs.map((portfolioDoc) => {
              const data = portfolioDoc.data();
              return (
                <div data-aos="fade-up" className='portfolio-Card' key={portfolioDoc.id}>
                  <Link to={data.Link} style={{ textDecoration: 'none' }}>
                    <img src={data.imageUrl} alt={data.title} style={{ maxWidth: '100%' }} />
                    <h3 className='ptitle'>{data.title}</h3>
                    <p className='pparagraph'>{truncateText(data.p1, 10)}</p>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
