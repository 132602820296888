import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Player from 'react-lottie-player';

const NotFound = () => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    // Load the JSON animation data from the public folder
    fetch('/img/connection-error.json')
      .then((response) => response.json())
      .then((data) => setAnimationData(data));
  }, []);

  return (
    <div style={styles.container}>
     
      {animationData && (
        <Player
          loop
          animationData={animationData}
          play
          style={styles.lottie}
        />
      )}
      <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" style={styles.button}>Go Back Home</Link>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: '20px',
    color: '#333',
  },
  lottie: {
    width: 300,
    height: 300,
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '20px',
    color: '#555',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
  },
};

export default NotFound;
