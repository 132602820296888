import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initOTPless, Authenticate } from './otpless'; // Ensure this path is correct
import { getAuth, signInWithCustomToken, sendEmailVerification, linkWithCredential, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { textdb } from '../../firebaseConfig';
import "./Register.css";
import { useNavigate } from 'react-router-dom';

const auth = getAuth();
const db = getFirestore();
const Register = () => {
    const [isWaitingForPhoneResponse, setIsWaitingForPhoneResponse] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [isVerificationLinkSent, setIsVerificationLinkSent] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const navigate = useNavigate();



    
    
   
    useEffect(() => {
       
        initOTPless(() => {});
    }, []);
    
    const handleEmailVerification = async () => {
        if (!email) {
            toast.error('Email is required.');
            return;
        }
    
        try {
            setIsWaitingForResponse(true);
            const timeout = setTimeout(() => {
                setIsWaitingForResponse(false);
                toast.error('No response from OTPless. Please try again.');
            }, 30000);
    
            initOTPless(async (response) => {
                clearTimeout(timeout);
                setIsWaitingForResponse(false);
    
                if (response.status === 'SUCCESS') {
                    const identity = response.identities.find(id => id.identityType === 'EMAIL');
                    const validatedEmail = identity ? identity.identityValue : null;
                    const token = response.token;
    
                    if (validatedEmail) {
                        setEmail(validatedEmail);
    setIsEmailVerified(true);
    toast.success('Email verified successfully!');
    localStorage.setItem('email', validatedEmail);  // Ensure email is stored here
    localStorage.setItem('authToken', token);
                        // Check if the user exists in Firestore
                        const userQuery = query(collection(db, 'users'), where('email', '==', validatedEmail));
                        const userSnapshot = await getDocs(userQuery);
    
                        if (userSnapshot.empty) {
                            // Proceed with registration if the user does not exist
                            await updateUserInFirestore([{ identityType: 'EMAIL', identityValue: validatedEmail }]);
                        } else {
                            toast.success('User already registered. Proceed with phone verification.');
                        }
                    } else {
                        toast.error('Email is missing in the OTPless response.');
                    }
                } else {
                    toast.error('Failed to verify email.');
                }
            });
    
            Authenticate({ channel: 'EMAIL', email });
        } catch (error) {
            setIsWaitingForResponse(false);
            console.error('Email verification error:', error);
            toast.error('Failed to verify email.');
        }
    };
    
    
    
    const handleFirebaseSignIn = async (otplessToken) => {
        try {
            const email = localStorage.getItem('email');  // Ensure email is retrieved here
            if (!email) {
                throw new Error('Email is missing.');
            }
    
            const response = await fetch('http://43.204.115.12/generateCustomToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ uid: otplessToken, email }),  // Send both UID and email
            });
    
            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(`Failed to fetch custom token: ${errorDetails.error}`);
            }
    
            const data = await response.json();
            const customToken = data.token;
    
            await signInWithCustomToken(auth, customToken);
            navigate('/service');
        } catch (error) {
            console.error('Firebase sign-in error:', error);
            toast.error(`Failed to sign in: ${error.message}`);
        }
    };
    
    
    
    const updateUserInFirestore = async (identities) => {
        try {
            const userId = auth.currentUser.uid;
            const userRef = doc(db, 'users', userId);
    
            const userData = {
                uid: userId,
                role: 'user',
            };
    
            identities.forEach(identity => {
                if (identity.identityType === 'EMAIL') {
                    userData.email = identity.identityValue;
                }
                if (identity.identityType === 'MOBILE') {
                    userData.phone = identity.identityValue;
                }
            });
    
            await setDoc(userRef, userData, { merge: true });
            navigate('/service');
        } catch (error) {
            console.error('Error updating Firestore:', error);
            toast.error(`Failed to update user data: ${error.message}`);
        }
    };
    
    
    
    
    useEffect(() => {
        if (isEmailVerified ) {
            handleFirebaseSignIn(localStorage.getItem('authToken'));
        }
    }, [isEmailVerified]);


    const handleGoogleVerification = async () => {
        console.log('Initiating Google verification with Firebase');

        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Check if the user already exists in Firestore
            const userQuery = query(collection(db, 'users'), where('email', '==', user.email));
            const userSnapshot = await getDocs(userQuery);

            if (!userSnapshot.empty) {
                // User already exists, just log them in
                toast.success('Logged in successfully!');
                navigate('/service');
            } else {
                // If user does not exist, update Firestore and register the user
                await updateUserInFirestore([{ identityType: 'EMAIL', identityValue: user.email }]);
                toast.success('Google verification successful!');
                navigate('/service');
            }
        } catch (error) {
            // console.error('Error during Google verification:', error);
            toast.error('Error during Google verification.');
        }
    };

    return (
        <div className="main-register-container">
            <div className="image-container">
                <img src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1710527710/j58dvyugfjtpxd3ywjes.png" alt="Image Description" />
            </div>

            <div className="register-container">
                <div className="register-content">
                    <p>WELCOME TO</p>
                    <h2>SECONDPUNDIT</h2>
                </div>

                <div className="verification-container">
                    <div className="email-verification">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isEmailVerified}
                            className="premium-input"
                        />
                        <button onClick={handleEmailVerification} disabled={isEmailVerified || isWaitingForResponse} className="premium-button">
    {isEmailVerified ? 'Email Verified' : isWaitingForResponse ? 'Waiting...' : 'Continue'}
</button>
                    </div>

                  

                    <hr className='google-hr'/>
                    <div className="google-veri-container">
                        <div className="google-verification">
                            <span onClick={handleGoogleVerification} className="premium-button-mobile">
                                <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1723456737/qrfzee6fywhyjqfxaijg.svg' alt="Google logo" /> Continue With Google
                            </span>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};

export default Register;