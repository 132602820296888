import React, { useState, useEffect, useRef } from 'react';
import { ref, set, onValue, push, update, get } from 'firebase/database';
import { storage, database, auth ,textdb } from '../../firebaseConfig'; 
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './CuChat.css';
import { FaPaperPlane, FaDownload, FaPlus, FaArrowLeft, FaArrowRight, FaTimes ,FaEllipsisH ,FaTrash } from 'react-icons/fa';
import { collection, doc,  getDoc } from 'firebase/firestore';
import { IoSend } from "react-icons/io5";
import { FaFolderPlus } from "react-icons/fa6";

const CuChat = () => {
    const [message, setMessage] = useState('');
    const [adminMessage, setAdminMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [serviceTitleSent, setServiceTitleSent] = useState(false);
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState('');
    const [previewURL, setPreviewURL] = useState('');
    const [loading, setLoading] = useState(false);
    const currentUser = auth.currentUser;
    const navigate = useNavigate();
    const { userId } = useParams();
    const location = useLocation();
    const messagesEndRef = useRef(null);
    const [pdfTitle, setPdfTitle] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleDeleteClick = () => {
        setIsPopupOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (userId) {
            const chatRef = ref(database, `chats/${userId}`);
            
            try {
              
                await set(chatRef, null); 
    
                console.log('All messages deleted');
                toast.success('All messages have been deleted');
            } catch (error) {
                console.error('Error deleting messages:', error);
                toast.error('Error deleting messages');
            }
            
            setIsPopupOpen(false);
        }
    };
    

    const handleDeleteCancel = () => {
        setIsPopupOpen(false);
    };

    const openModal = (imageURL, index) => {
        setCurrentImage(imageURL);
        setCurrentIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleNextImage = () => {
        const nextIndex = (currentIndex + 1) % messages.length;
        setCurrentImage(messages[nextIndex].fileURL);
        setCurrentIndex(nextIndex);
    };

    const handlePrevImage = () => {
        const prevIndex = (currentIndex - 1 + messages.length) % messages.length;
        setCurrentImage(messages[prevIndex].fileURL);
        setCurrentIndex(prevIndex);
    };

    const getServiceTitleFromQuery = () => {
        const searchParams = new URLSearchParams(location.search);
        const title = searchParams.get('title');
        return typeof title === 'string' ? decodeURIComponent(title) : '';
    };

    const serviceTitle = getServiceTitleFromQuery();

    const getUserRole = async (user) => {
        if (user) {
            try {
                const userDocRef = doc(collection(textdb, 'users'), user.uid);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    return userData.role || 'user';
                }
            } catch (error) {
                console.error('Error fetching user data from Firestore:', error);
            }
        }

        return 'user';
    };

    useEffect(() => {
        const fetchData = async () => {
            if (currentUser) {
                const userRole = await getUserRole(currentUser);
                setUserName(currentUser.displayName || '');
                setUserRole(userRole);
            }
        };
        fetchData();
    }, [currentUser]);

    useEffect(() => {
        if (userId) {
            const chatRef = ref(database, `chats/${userId}`);

            onValue(chatRef, (snapshot) => {
                const messagesData = [];
                snapshot.forEach((childSnapshot) => {
                    messagesData.push({ id: childSnapshot.key, ...childSnapshot.val() });
                });

                messagesData.sort((a, b) => a.timestamp - b.timestamp);
                setMessages(messagesData);
            });
        }
    }, [userId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const clearFileInput = () => {
        setFile(null);
        setPreviewURL('');
        setPdfTitle('');
        document.getElementById('fileInput').value = null; // Reset the input field
    };
    

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
    
        if (selectedFile.size > 10 * 1024 * 1024) { // 10 MB limit
            toast.error('File size exceeds 10MB. Please upload a smaller file.');
            return;
        }
    
        setFile(selectedFile);
    
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewURL(reader.result);
            };
            reader.readAsDataURL(selectedFile);
    
            if (selectedFile.type === 'application/pdf') {
                setPdfTitle(selectedFile.name);
            } else {
                setPdfTitle('');
            }
        } else {
            setPreviewURL('');
            setPdfTitle('');
        }
    };

    const sendMessage = async () => {
        if (!currentUser) {
            toast.error('Unauthorized access. Please log in.');
            return;
        }
        if (!message.trim() && !file) {
            toast.error('Please enter a message or upload a file before sending.');
            return;
        }
        setLoading(true);

        try {
            const chatRef = ref(database, `chats/${userId}`);
            const timestamp = Date.now();

            let finalMessage = message;

            if (serviceTitle && !serviceTitleSent) {
                finalMessage = `${serviceTitle}: ${message}`;
                setServiceTitleSent(true);
            }

            if (file) {
                const storageReference = storageRef(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageReference, file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Handle progress if needed
                    },
                    (error) => {
                        console.error('Error uploading file:', error);
                        toast.error('Error uploading file');
                        setLoading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setFileURL(downloadURL);
                            sendMessageTodatabase(chatRef, finalMessage, timestamp, downloadURL);
                            setLoading(false);
                            clearFileInput();
                        });
                    }
                );
            } else {
                sendMessageTodatabase(chatRef, finalMessage, timestamp);
                setLoading(false);
            }

            setMessage('');
            setAdminMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Error sending message');
            setLoading(false);
        }
    };

    const sendMessageTodatabase = async (chatRef, message, timestamp, fileURL = '') => {
        const newMessageRef = push(chatRef);

        const messageData = {
            userId: userRole === 'admin' ? 'Admin' : currentUser.uid,
            userName: userRole === 'admin' ? 'Admin' : userName,
            message: userRole === 'admin' ? adminMessage : message,
            timestamp,
            fileURL,
        };

        await set(newMessageRef, messageData);
    };
    
    const handleDownload = async (fileURL) => {
        try {
            // Fetch the file from Firebase Storage
            const response = await fetch(fileURL);
            const blob = await response.blob(); // Convert the response to a Blob
    
            // Create a temporary link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob
    
            link.href = url; // Set href to the object URL
            link.download = fileURL.split('%2F').pop().split('?')[0]; // Extract file name from URL
            link.click(); // Programmatically click the link to start the download
    
            // Clean up
            window.URL.revokeObjectURL(url); // Revoke the object URL
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Error downloading file');
        }
    };
    
    const extractFileName = (fileURL) => {
        // Decode the URL and extract the filename
        const decodedURL = decodeURIComponent(fileURL);
        const urlParts = decodedURL.split('/');
        return urlParts[urlParts.length - 1].split('?')[0];
    };
    
    const renderMessagesWithDate = () => {
        let currentDate = null;
        return messages.map((msg, index) => {
            const messageDate = new Date(msg.timestamp).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            const previousMessageDate = index > 0 ? new Date(messages[index - 1].timestamp).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }) : null;
    
            const showDate = messageDate !== previousMessageDate && messageDate !== currentDate;
            currentDate = messageDate;
    
            const dateComponent = showDate ? <div className="message-date">{messageDate}</div> : null;
    
            return (
                <React.Fragment key={msg.id}>
                    {dateComponent}
                    <div
                        className="message"
                        style={{
                            alignSelf: userRole === 'admin' ? (msg.userName === 'Admin' ? 'flex-end' : 'flex-start') : (msg.userName === 'Admin' ? 'flex-start' : 'flex-end'),
                            backgroundColor: userRole === 'admin' ? (msg.userName === 'Admin' ? '#d1eaf4' : '#f3f3f3') : (msg.userName === 'Admin' ? '#f3f3f3' : '#d1eaf4')
                        }}
                    >
                        <p>
                            <strong>{msg.userName}</strong>: {msg.message} <br />
                            <span className="timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</span>
                        </p>
                        {msg.fileURL && (
                            <div className="message-file">
                                {msg.fileURL.endsWith('.pdf') ? (
                                    <div className="pdf-container">
                                        <embed src={msg.fileURL} type="application/pdf" width="100%" height="500px" />
                                        <p className="file-name">{extractFileName(msg.fileURL)}</p>
                                        <button className="download-btn" onClick={() => handleDownload(msg.fileURL)}>
                                            <FaDownload /> 
                                        </button>
                                    </div>
                                ) : msg.fileURL.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                    <div className="image-container">
                                        <img src={msg.fileURL} alt="Uploaded File" className="message-image" />
                                        <p className="file-name">{extractFileName(msg.fileURL)}</p>
                                        <button className="download-btn" onClick={() => handleDownload(msg.fileURL)}>
                                            <FaDownload /> 
                                        </button>
                                    </div>
                                ) : msg.fileURL.endsWith('.xlsx') ? (
                                    <div className="file-container">
                                        <p className="file-name">{extractFileName(msg.fileURL)}</p>
                                        <a href={msg.fileURL} download className="download-btn">
                                            <FaDownload /> 
                                        </a>
                                    </div>
                                ) : (
                                    <div className="file-container">
                                        <img className='mesgimg' src={msg.fileURL}  onClick={() => openModal(msg.fileURL, index)} />
                                        <p className="file-name">{extractFileName(msg.fileURL)}</p>
                                        <a href={msg.fileURL} download className="download-btn">
                                            <FaDownload /> 
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            );
        });
    };

    
    return (
        <div className="chat-container">
         {userRole === 'admin' &&  <div onClick={handleDeleteClick} className='chat-threedot'>
  <FaTrash />
</div>}

        <div className="messages-container">
            <h3>Welcome, {userName} ({userRole})</h3>
            {renderMessagesWithDate()}
            <div ref={messagesEndRef} />
        </div>
        {serviceTitle && !serviceTitleSent && (
            <div className="service-title">
                <p><strong></strong> {serviceTitle}</p>
            </div>
        )}
       {modalOpen && currentImage && (
<div className="modal-overlay" onClick={closeModal}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
            <button title='Download Now' className="download-btn-modal" onClick={() => handleDownload(currentImage)}><FaDownload /> </button>
            <button className="close-btn" onClick={closeModal}><FaTimes /></button>
        </div>
        <div className="modal-body">
            <img
                src={currentImage}
                alt="Modal"
                className='modalimg'
            />
        </div>
        <div className="modal-footer">
            <button className="prev-btn" onClick={handlePrevImage}><FaArrowLeft /></button>
            <button className="next-btn" onClick={handleNextImage}><FaArrowRight /></button>
        </div>
    </div>
</div>
)}
{isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>Are you sure you want to delete all messages?</p>
                        <div className="popup-buttons">
                            <button className="popup-button delete" onClick={handleDeleteConfirm}>
                                Delete
                            </button>
                            <button className="popup-button cancel" onClick={handleDeleteCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

{previewURL && (
  <div className="file-preview-modal" onClick={clearFileInput}>
    <div className="file-preview-content" onClick={(e) => e.stopPropagation()}>
      {previewURL.endsWith('.pdf') ? (
        <div>
          <embed src={previewURL} type="application/pdf" width="100%" height="500px" />
          <p className="pdf-title">{pdfTitle}</p>
        </div>
      ) : (
        <div>
          <img src={previewURL} alt="Preview" className="image-preview" />
          <p className="image-title">{file ? file.name : ''}</p> {/* Display the file name */}
          <button className="clear-btn" onClick={clearFileInput}>X</button>
        </div>
      )}
    </div>
  </div>
)}


        <div className='main-input'>

            <div className="input-container">
            <textarea
    className='inputtext'
    required
    style={{ 
        resize: 'none', 
        overflowX: 'hidden', 
        fontSize: '13px', // Set font size to 13px
        lineHeight: '1.5', // Adjust the line-height for better readability
        maxHeight: `${2 * 24}px`  // Max height equivalent to 4 rows of 24px each
    }}
    value={userRole === 'admin' ? adminMessage : message}
    onChange={(e) => userRole === 'admin' ? setAdminMessage(e.target.value) : setMessage(e.target.value)}
    rows={1}
    onInput={(e) => {
        e.target.style.height = ''; // Reset height to calculate new height
        const currentHeight = e.target.scrollHeight;
        const maxHeight = 2 * 24;  // 4 rows of 24px each (adjust this based on actual font and line height)
        
        if (currentHeight <= maxHeight) {
            e.target.style.height = `${currentHeight}px`;  // Set height to fit content
        } else {
            e.target.style.height = `${maxHeight}px`;  // Limit to max height of 4 rows
            e.target.style.overflowY = 'auto';  // Enable scroll if max height is reached
        }
    }}
/>

                <div className="file-upload">
                    <label htmlFor="fileInput" className="file-label">
                        <FaFolderPlus className="plus-icon" />

                    </label>
                    <input
                        id="fileInput"
                        type="file"
                        key={file ? file.name : ''}  // Add key prop to force re-render
                        onChange={handleFileUpload}
                        className="file-input"
                        style={{ display: 'none' }}
                    />
                </div>
                <button className='inputbtn' onClick={sendMessage} disabled={loading}>
                    {loading ? '...' : <IoSend/>}
                </button>
            </div>
        </div>

    </div>
    );
};

export default CuChat;
