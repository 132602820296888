import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import './UserInfoModal.css';

const UserInfoModal = ({ isOpen, onClose }) => {
  const [userName, setUserName] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [userEmail, setUserEmail] = useState(''); // State for email
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    if (isOpen) {
      fetchUserData();
    }
  }, [isOpen]);

  const fetchUserData = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        setUserName(userData.name || '');
        setUserMobile(userData.mobile || '');
      } else {
        console.log('No such document!');
      }

      // Set the user's email from the authentication object
      const email = auth.currentUser?.email;
      console.log('User Email:', email); // Debugging line
      setUserEmail(email || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to fetch user data.');
    }
  };

  const handleSave = async () => {
    if (!userName || !userMobile) {
      toast.error('Name and mobile number are required.');
      return;
    }

    setIsLoading(true);
    const userId = auth.currentUser.uid;
    const userRef = doc(db, 'users', userId); 

    try {
      await setDoc(userRef, { name: userName, phone: userMobile }, { merge: true });
      toast.success('Profile updated successfully!');
      onClose();
    } catch (error) {
      console.error('Error saving user data:', error);
      toast.error('Failed to save user data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">
        <h2 className="modal-title">Complete Your Profile</h2>
        <div className="modal-input-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            value={userEmail}
            readOnly // Make it read-only
          />
        </div>
        <div className="modal-input-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your name"
          />
        </div>
        <div className="modal-input-group">
          <label htmlFor="mobile">Mobile Number</label>
          <input
            type="text"
            id="mobile"
            value={userMobile}
            onChange={(e) => setUserMobile(e.target.value)}
            placeholder="Enter your mobile number"
          />
        </div>
        <div className="modal-actions">
          <button onClick={handleSave} className="save-button" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </button>
          <button onClick={onClose} className="close-button">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoModal;
