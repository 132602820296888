var OTPlessSignin = null;

/**
 * Creates a script element to load the OTPless SDK.
 *
 * @return {void} No return value
 */
const loadScript = () => {
    if (document.getElementById('otpless-sdk')) return; // Skip if already loaded
    
    const script = document.createElement('script');
    script.id = 'otpless-sdk';
    script.type = 'text/javascript';
    script.src = 'https://otpless.com/v2/headless.js';
    script.setAttribute('data-appid', 'PI726MMK2CQXA8VENTE5'); // Replace with your OTPless App ID
    script.onload = () => {
        console.log('OTPless SDK loaded successfully');
        // Initialize OTPlessSignin only after the script is loaded
        OTPlessSignin = new window.OTPless(() => {});
    };
    document.head.appendChild(script);
};


/**
 * Initializes the OTPless SDK and sets up a callback function.
 *
 * @param {function} callback - The callback function to be executed after successful authentication.
 * @return {void} No return value.
 */
export const initOTPless = (callback) => {
    // Loading the script if it's not already loaded
    if (!document.getElementById('otpless-sdk')) loadScript();

    // Initializing the OTPless SDK after 1 second to allow the script to load.
    setTimeout(() => {
        OTPlessSignin = new window.OTPless(callback);
    }, 1000);
};

/**
 * Authenticates the user using any authentication method available.
 *
 * @param {Object} params - The parameters for primary authentication.
 * @param {string} [params.channel='PHONE'] - The authentication channel (EMAIL, PHONE, OAUTH).
 * @param {string} [params.channelType] - For social authentication, e.g., GOOGLE, FACEBOOK.
 * @param {string} [params.phone] - The user's phone number.
 * @param {string} [params.email] - The user's email address.
 * @param {string} [params.countryCode='+91'] - The country code for the phone number.
 * @return {Promise} A promise that resolves with the result of the authentication.
 */
export const Authenticate = ({ channel = 'PHONE', channelType, phone, countryCode = '+91', email }) => {
    if (channel !== 'OAUTH' && channel !== 'EMAIL' && channel !== 'PHONE') {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: `Invalid channel ${channel}`,
        });
    }
    if (channel === 'EMAIL' && !email) {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: 'Email is required',
        });
    }
    if (channel === 'PHONE' && !phone) {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: 'Phone is required',
        });
    }
    if (channel === 'OAUTH' && !channelType) {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: 'Channel type is required',
        });
    }
    return OTPlessSignin && OTPlessSignin.initiate({
        channel,
        channelType,
        phone,
        email,
        countryCode,
    });
};

/**
 * Verifies the OTP (One-Time Password) for the given authentication channel.
 *
 * @param {Object} params - The parameters for verifying the OTP.
 * @param {string} [params.channel='PHONE'] - The authentication channel (default: 'PHONE').
 * @param {string} otp - The OTP to be verified.
 * @param {string} [params.countryCode='+91'] - The country code for the phone number (default: '+91').
 * @param {string} [params.phone] - The user's phone number.
 * @param {string} [params.email] - The user's email address.
 * @return {Promise} A promise that resolves with the result of the verification.
 */
export const verifyOTP = ({ channel = 'PHONE', otp, countryCode = '+91', phone, email }) => {
    if (channel !== 'EMAIL' && channel !== 'PHONE') {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: `Invalid channel ${channel}`,
        });
    }
    if (channel === 'EMAIL' && !email) {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: 'Email is required',
        });
    }
    if (channel === 'PHONE' && !phone) {
        return Promise.reject({
            success: false,
            statusCode: 400,
            errorMessage: 'Phone is required',
        });
    }
    return OTPlessSignin && OTPlessSignin.verify({
        channel,
        phone,
        email,
        otp,
        countryCode,
    });
};
