import React, { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { textdb, auth } from '../../firebaseConfig';
import { collection } from 'firebase/firestore';
import './CardServices.css';
import { useNavigate } from 'react-router-dom';
import Load from '../Loder/Load';

const CardServices = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [Services, loading, error] = useCollection(collection(textdb, 'Services'));

    const navigate = useNavigate();

    useEffect(() => {
        setCurrentUser(auth.currentUser);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const truncateText = (text, maxLength) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
    };

    const handleCardClick = (serviceId, title) => {
        if (!currentUser) {
            navigate('/register'); // Redirect to register page if not authenticated
        } else {
            navigate(`/cuchat/${currentUser.uid}?title=${encodeURIComponent(`i need ${title}`)}`);
        }
    };

    if (loading) return <Load />;
    if (error) return <p>Error fetching Services</p>;

    return (
        <div className='main-cardService'>
            <h1>Thank you for your interest in <span style={{ color: "#3843d0", fontWeight: "700" }}>SECONDPUNDIT</span></h1>
            <h2>What role would you like to hire?</h2>
            <div className='hiring-card'>
                <div className='Service-section-new-hire'>
                    {Services && Services.docs.map((Service) => {
                        const data = Service.data();
                        return (
                            <div 
                                key={Service.id} 
                                onClick={() => handleCardClick(Service.id, data.title)} 
                                className='Service-link' 
                                style={{ textDecoration: "none", cursor: "pointer" }}
                            >
                                <div className='service-card-hire'>
                                    <div>
                                        <h3 className='hire-card-Service-title'>{data.title}</h3>
                                        <p className='card-Service-p'>{truncateText(data.p1, 10)}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='cardhire-img11'>
                    <img src='img/hireillustration.png' alt='hire' />
                </div>
            </div>
        </div>
    );
}

export default CardServices;
